import React from 'react'
import { Button, Divider, Segment, Header, Grid } from 'semantic-ui-react'

import SimpleReactLightbox from "simple-react-lightbox";
import { SRLWrapper } from "simple-react-lightbox";

import olliverEllsworth from '../images/articlePictures/oliverEllsworth.png'
import marshall from '../images/articlePictures/marshall.png'
import marshallPanama from '../images/articlePictures/marshallPanama.png'
import japaneseSurvivors from  '../images/articlePictures/japaneseSurvivors.png'
import cohocton from  '../images/articlePictures/cohocton.png'
import bars from '../images/articlePictures/bars.png'
import clyde from '../images/articlePictures/clydeService.png'




const ClydeWW2 = () => (
<React.Fragment>
<Segment  style={{ padding: '0em 0em' }} vertical>
<SimpleReactLightbox>
    <SRLWrapper>
    <Grid celled='internally' columns='equal' stackable>
        <Grid.Row textAlign='center'>
            <Grid.Column  style={{ paddingBottom: '0em', paddingTop: '0em' }}>
                <br/>
                <Header as='h3' style={{ fontSize: '2em' }}>Clyde Richardson <br/> World War 2 Service </Header>
                <img src={clyde} class="articlePhoto"  alt="Clyde Richardson" />
                <br/><br/><br/>

            </Grid.Column>
        </Grid.Row>

        <Grid.Row textAlign='center'>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <Header as='h3' style={{ fontSize: '1.5em' }}>December 1941</Header>
            </Grid.Column>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <p style={{ fontSize: '1em' }}>
                    Gainesville, Fl—Went to enlist and even though he was thirty years old, he was told that he needed his parent’s signature.  He went outside, signed his parent’s names and was accepted.
                </p>
            </Grid.Column>
        </Grid.Row>

        <Grid.Row textAlign='center'>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <Header as='h3' style={{ fontSize: '1.5em' }}>December 22, 1941</Header>
            </Grid.Column>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <p style={{ fontSize: '1em' }}>
                        Enlisted as an Apprentice Seaman for $21.00 per month at Navy Recruiting Station, Macon, Georgia.
                </p>
            </Grid.Column>
        </Grid.Row>

        <Grid.Row textAlign='center'>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <Header as='h3' style={{ fontSize: '1.5em' }}>December 23, 1941</Header>
            </Grid.Column>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <p style={{ fontSize: '1em' }}>
                    Reported to Navy Training Center, Norfolk, Virginia for Boot Camp.
                </p>
            </Grid.Column>
        </Grid.Row>

        <Grid.Row textAlign='center'>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <Header as='h3' style={{ fontSize: '1.5em' }}>March 14, 1942</Header>
            </Grid.Column>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <p style={{ fontSize: '1em' }}>
                 Transferred to Armed Guard Center, Little Creek, Virginia for instruction.
                </p>
            </Grid.Column>
        </Grid.Row>

        <Grid.Row textAlign='center'>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <Header as='h3' style={{ fontSize: '1.5em' }}>April 2, 1942</Header>
            </Grid.Column>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <p style={{ fontSize: '1em' }}>
                Departed Armed Guard Center
                </p>
            </Grid.Column>
        </Grid.Row>

        <Grid.Row textAlign='center'>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <Header as='h3' style={{ fontSize: '1.5em' }}>April 3, 1942</Header>
            </Grid.Column>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <p style={{ fontSize: '1em' }}>
                SS Geminy – Lost screw in the Northern Atlantic.  When told to shoot anything that moved.  He popped off a few rounds of 20 millimeter at a whale.  Missed the whale but caught a mouthful from his CO.
                <br/>
		        Stopped at Halifax, Novascotia, Iceland, and Greenland.
                </p>
            </Grid.Column>
        </Grid.Row>

        <Grid.Row textAlign='center'>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <Header as='h3' style={{ fontSize: '1.5em' }}>May 29, 1942</Header>
            </Grid.Column>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <p style={{ fontSize: '1em' }}>
                     Advanced to Seaman 1 class.
                </p>
            </Grid.Column>
        </Grid.Row>

        <Grid.Row textAlign='center'>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <Header as='h3' style={{ fontSize: '1.5em' }}>July 15, 1942</Header>
            </Grid.Column>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <p style={{ fontSize: '1em' }}>
                        On leave.
                </p>
            </Grid.Column>
        </Grid.Row>

        <Grid.Row textAlign='center'>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <Header as='h3' style={{ fontSize: '1.5em' }}>August 4, 1942</Header>
            </Grid.Column>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <p style={{ fontSize: '1em' }}>
                SS Oliver Ellsworth - Crew on a 20 millimeter gun mount.
                </p>
            </Grid.Column>
        </Grid.Row>

        <Grid.Row textAlign='center'>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <Header as='h3' style={{ fontSize: '1.5em' }}>August 9, 1942</Header>
            </Grid.Column>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <p style={{ fontSize: '1em' }}>
                        In Halifax, Novascotia for 5 or 6 days.
                </p>
            </Grid.Column>
        </Grid.Row>

        <Grid.Row textAlign='center'>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <Header as='h3' style={{ fontSize: '1.5em' }}>August 24, 1942</Header>
            </Grid.Column>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <p style={{ fontSize: '1em' }}>
                        In Iceland for 2 or 3 days.
                </p>
            </Grid.Column>
        </Grid.Row>

        <Grid.Row textAlign='center'>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <Header as='h3' style={{ fontSize: '1.5em' }}>September 13, 1942 </Header>
            </Grid.Column>
            <Grid.Column textAlign='left' style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <p style={{ fontSize: '1em' }}>
                    SS Oliver Ellsworth torpedoed and sunk by the U-408 during Murmansk Convoy PQ-18.
                    <br/>
                    <br/>
                    The Sea was calm and the sky was overcast.  The SS Oliver Ellsworth, a 7,191-ton liberty ship that was not yet three months old.  She sailed with convoy PQ-18 and was just west of the Southern Cape of Spitsbergen, headed for Murmansk.  Each sailor knew the disaster that happened to the previous convoy, PQ-17.  The Convoy had been at sea since September 2, when it departed Loch Ewe in Northwest Scotland.  Yesterday, after several alarms, definite contact was made with a German patrol plane.  Now 40 merchant ships, 24 destroyers, an escort carrier and several smaller vessels were waiting for the inevitable attack.
                    <br/>
                    <br/>
	                On the starboard side of the convoy, the SS Oliver Ellsworth, a freighter, kept station.  It was just a little before 8:00 A.M. and the crew was just finishing up breakfast.  Petty Officer Third Class Clyde Richardson and a young merchant sailor emerged on deck from the Mess Decks.  General Quarters was to be called soon.
                    <br/>
                    <br/>
	                While standing by the rail, amidships, they both spotted a periscope about 200 yards away.  As they watched, it slipped under the waves.  A short time later a torpedo slammed into the stern.  The ship bucked and then began to settle by the stern.
                    <br/>
                    <br/>
	                Petty Officer Richardson ran for his General Quarters station and strapped himself to his 20-millimeter anti-aircraft gun.  Richardson soon realized that the ship was going to sink.    He unstrapped himself and began to make his way aft to his berthing area to get his life jacket.  After seeing water beginning to come over the stern, he changed direction to his abandon ship station.
                     <br/>
                     <br/>
	                The Ellsworth had four lifeboats.  Petty Officer Richardson’s station was the second boat on the port side.  By the time he arrived, the first boat was being lowered.  The man handling the forward line let it go to soon and the boat hit the water bow first and capsized.  The second boat made it.
                    <br/>
                    <br/>
	                There, being no ladder, Richardson jumped down the eight feet from the deck to the boat.  He and the three other sailors tried to hold the boat next to the ship so others could board it.  As the momentum of the ship carried the ship forward and the waves pushed the boat back, the men lost in their effort to keep the boat along side the Ellsworth.
                    <br/>
                    <br/>
	                They began to search and pick up other crewmembers that had jumped overboard. One crewman that made it was Charles R. Cronk.   In less than an hour the British armed trawler HMS St. Kenan picked them up and continued looking for survivors.  Later the St. Kenan sunk the Ellsworth with gunfire.
                    <br/>
                    <br/>
	                Later in the day the crew was transferred to a British light Cruiser.  30 to 40 Junkers 88s and Heinkel 111s began their attack on the convoy.  The Ellsworth gun crews were assigned to help the British gunners, as they attempted to knock down German Planes that flew overhead.  They just got brief glimpses of them through the low clouds and were unable to score any hits as the Cruiser left the convoy to return to Scapa Flow.
                    <br/>
                    <br/>
	                The following notation was entered in Clyde Richardson’s service record-“Performed meritorious service as a member of the Armed Guard Crew of a merchant vessel during attack and subsequent sinking of vessel by an enemy submarine on 13 September 1942.”
                    <br/>
                    <br/>
	                A couple of days later they arrived at Scapa Flow.  Only one of the Ellsworth crew was lost.  Of the 40 merchantmen that started with the convoy, 13 were lost.  The U-Boat that sunk the Ellsworth was the U-589.  Later that day the U-589 picked up four German Airmen from the water.  The very next day the HMS Onslow sunk the U-589 with Captain Hans-Joachim Horrer and all hands. 
                    <br/>
                    <br/>
	                The following notation was entered in Petty Officer Richardson’s service record-“Performed meritorious service as a member of the Armed Guard Crew of a merchant vessel during attack and subsequent sinking of vessel by an enemy submarine on 13 September 1942.”
                </p>
                    <img src={olliverEllsworth} class="articlePhoto" alt="SS Oliver Ellsworth in 1942" />
            </Grid.Column>
        </Grid.Row>

        <Grid.Row textAlign='center'>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <Header as='h3' style={{ fontSize: '1.5em' }}>September 17, 1942</Header>
            </Grid.Column>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <p style={{ fontSize: '1em' }}>
                        Scapa Flow
                </p>
            </Grid.Column>
        </Grid.Row>

        <Grid.Row textAlign='center'>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <Header as='h3' style={{ fontSize: '1.5em' }}>September 18, 1942</Header>
            </Grid.Column>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <p style={{ fontSize: '1em' }}>
                        Glasgow, Scotland
                </p>
            </Grid.Column>
        </Grid.Row>

        <Grid.Row textAlign='center'>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <Header as='h3' style={{ fontSize: '1.5em' }}>Setepmber 23, 1942</Header>
            </Grid.Column>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <p style={{ fontSize: '1em' }}>
                        Roseneth, Scotland
                </p>
            </Grid.Column>
        </Grid.Row>

        <Grid.Row textAlign='center'>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <Header as='h3' style={{ fontSize: '1.5em' }}>October 20, 1942</Header>
            </Grid.Column>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <p style={{ fontSize: '1em' }}>
                    Liverpool, England
                </p>
            </Grid.Column>
        </Grid.Row>

        <Grid.Row textAlign='center'>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <Header as='h3' style={{ fontSize: '1.5em' }}>October 22, 1942</Header>
            </Grid.Column>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <p style={{ fontSize: '1em' }}>
                    On ship in transit to United States
                </p>
            </Grid.Column>
        </Grid.Row>

        <Grid.Row textAlign='center'>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <Header as='h3' style={{ fontSize: '1.5em' }}>November 1 - 20, 1942</Header>
            </Grid.Column>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <p style={{ fontSize: '1em' }}>
                    On Leave
                </p>
            </Grid.Column>
        </Grid.Row>

        <Grid.Row textAlign='center'>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <Header as='h3' style={{ fontSize: '1.5em' }}>November 20, 1942</Header>
            </Grid.Column>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <p style={{ fontSize: '1em' }}>
                    Armed Guard Center - Brooklyn. Met Edna Frank at WMCA on December 6, 1942
                </p>
            </Grid.Column>
        </Grid.Row>

        <Grid.Row textAlign='center'>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <Header as='h3' style={{ fontSize: '1.5em' }}>December 18, 1942</Header>
            </Grid.Column>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <p style={{ fontSize: '1em' }}>
                SS Northern Sun—While Clyde was waiting for bus, watched fight break out.  When police came, the guilty parties ran.  He was picked up and spent the night in jail.  He was released the next day due to lack of evidence.
                </p>
            </Grid.Column>
        </Grid.Row>

        <Grid.Row textAlign='center'>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <Header as='h3' style={{ fontSize: '1.5em' }}>June 18, 1943</Header>
            </Grid.Column>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <p style={{ fontSize: '1em' }}>
                On leave.
                </p>
            </Grid.Column>
        </Grid.Row>

        <Grid.Row textAlign='center'>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <Header as='h3' style={{ fontSize: '1.5em' }}>July 1, 1943</Header>
            </Grid.Column>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <p style={{ fontSize: '1em' }}>
                U.S.N. Rest Center, College Arms Hotel, Deland, Florida
                </p>
            </Grid.Column>
        </Grid.Row>

        <Grid.Row textAlign='center'>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <Header as='h3' style={{ fontSize: '1.5em' }}>July 18, 1943</Header>
            </Grid.Column>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <p style={{ fontSize: '1em' }}>
                Armed Guard Center - Brooklyn. 
                </p>
            </Grid.Column>
        </Grid.Row>

        <Grid.Row textAlign='center'>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <Header as='h3' style={{ fontSize: '1.5em' }}>September 2, 1943</Header>
            </Grid.Column>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <p style={{ fontSize: '1em' }}>
                Washington D.C. for gunnery school
                </p>
            </Grid.Column>
        </Grid.Row>

        <Grid.Row textAlign='center'>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <Header as='h3' style={{ fontSize: '1.5em' }}>October 14, 1943</Header>
            </Grid.Column>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <p style={{ fontSize: '1em' }}>
                Receiving Station, Lido Beach, Long Island, New York for duty on the U.S.S. Marshall (DD-676)
                </p>
            </Grid.Column>
        </Grid.Row>

        <Grid.Row textAlign='center'>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <Header as='h3' style={{ fontSize: '1.5em' }}>October 16, 1943</Header>
            </Grid.Column>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <p style={{ fontSize: '1em' }}>
                Reported on board the U.S.S. Marshall (DD-676)—Assigned port side forward 40-millimeter AA gun.
                </p>
                <img src={marshall} class="articlePhoto" alt="USS Marshall (DD-676) in 1943" />
            </Grid.Column>
        </Grid.Row>

        <Grid.Row textAlign='center'>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <Header as='h3' style={{ fontSize: '1.5em' }}>December 13, 1943</Header>
            </Grid.Column>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <p style={{ fontSize: '1em' }}>
                During shakedown cruise off Bermuda, Marshall was directed to provide escort for the USS Iowa (BB-61) that was carrying President Roosevelt back from a Big Three meeting in Teheran.
                </p>
            </Grid.Column>
        </Grid.Row>

        <Grid.Row textAlign='center'>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <Header as='h3' style={{ fontSize: '1.5em' }}>January 6, 1944</Header>
            </Grid.Column>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <p style={{ fontSize: '1em' }}>
                The Marshall departed New York for Pearl Harbor via Panama Canal.
                </p>
                <img src={marshallPanama} class="articlePhoto" alt="The USS Marshall in the background in the Panama Canal" />
            </Grid.Column>
        </Grid.Row>

        <Grid.Row textAlign='center'>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <Header as='h3' style={{ fontSize: '1.5em' }}>January 28, 1944</Header>
            </Grid.Column>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <p style={{ fontSize: '1em' }}>
                The Marshall arrived at Pearl Harbor.  The ship underwent further training and acted as escort to battle damaged ships returning for repairs.
                </p>
            </Grid.Column>
        </Grid.Row>

        <Grid.Row textAlign='center'>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <Header as='h3' style={{ fontSize: '1.5em' }}>March 18, 1944</Header>
            </Grid.Column>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <p style={{ fontSize: '1em' }}>
                Crossed International Date Line.  Qualified as Golden Dragon.
                </p>
            </Grid.Column>
        </Grid.Row>

        <Grid.Row textAlign='center'>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <Header as='h3' style={{ fontSize: '1.5em' }}>March 20, 1944</Header>
            </Grid.Column>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <p style={{ fontSize: '1em' }}>
                Arrived Majuro with TG 58.2
                </p>
            </Grid.Column>
        </Grid.Row>

        <Grid.Row textAlign='center'>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <Header as='h3' style={{ fontSize: '1.5em' }}>March 25, 1944</Header>
            </Grid.Column>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <p style={{ fontSize: '1em' }}>
                Crossed Equator this date.  Qualified Shell Back.
                </p>
            </Grid.Column>
        </Grid.Row>

        <Grid.Row textAlign='center'>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <Header as='h3' style={{ fontSize: '1.5em' }}>March 29, 1944</Header>
            </Grid.Column>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <p style={{ fontSize: '1em' }}>
                Predawn General Quarters – Task Force shot down an enemy bomber. Marshall has picket duty 8 to 10 miles ahead of Task Force.  Went to GQ at 1930, sighted enemy planes.
                </p>
            </Grid.Column>
        </Grid.Row>

        <Grid.Row textAlign='center'>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <Header as='h3' style={{ fontSize: '1.5em' }}>March 30, 1944</Header>
            </Grid.Column>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <p style={{ fontSize: '1em' }}>
                Participated in the attack on Palau Island.  
                <br/>
                <br/>
                The first planes attack at 0630.  They laid down mine fields to keep Jap ships in harbor.  Attacked the Island.  Jap planes destroyed on the ground.  Island ablaze.   Two Jap Destroyers and six of the thirteen transports left burning.  Rescued one pilot and his gunner that was shot in the arm.  Eleven Jap planes attacked Task Force.  Seven of the planes were shot down and the other four made a run for it.
                </p>
            </Grid.Column>
        </Grid.Row>

        <Grid.Row textAlign='center'>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <Header as='h3' style={{ fontSize: '1.5em' }}>March 31, 1944</Header>
            </Grid.Column>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <p style={{ fontSize: '1em' }}>
                Task Forced attacked again.  This time the AA fire was stiffer.  The American Carriers lost 27 planes.  A DE destroyed a transport.  Later a Destroyer spotted a boat full of survivors and sunk it.
                </p>
            </Grid.Column>
        </Grid.Row>

        <Grid.Row textAlign='center'>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <Header as='h3' style={{ fontSize: '1.5em' }}>April 1, 1944</Header>
            </Grid.Column>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <p style={{ fontSize: '1em' }}>
                Participated in the attack on Woleai Island. The Americans lost about 40 planes today this day.
                </p>
            </Grid.Column>
        </Grid.Row>

        <Grid.Row textAlign='center'>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <Header as='h3' style={{ fontSize: '1.5em' }}>April 2, 1944</Header>
            </Grid.Column>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <p style={{ fontSize: '1em' }}>
                Day very hot.  Refueled from USS Cabot (CVL-28).  "They gave us 15 gallons of ice cream."
                </p>
            </Grid.Column>
        </Grid.Row>

        <Grid.Row textAlign='center'>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <Header as='h3' style={{ fontSize: '1.5em' }}>April 6, 1944</Header>
            </Grid.Column>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <p style={{ fontSize: '1em' }}>
                Still steaming towards Majuro.  Much rain and rough seas.  Payday.
                </p>
            </Grid.Column>
        </Grid.Row>

        <Grid.Row textAlign='center'>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <Header as='h3' style={{ fontSize: '1.5em' }}>April 8, 1944</Header>
            </Grid.Column>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <p style={{ fontSize: '1em' }}>
                Arrived in Majuro in the Marshall Islands.
                </p>
            </Grid.Column>
        </Grid.Row>

        <Grid.Row textAlign='center'>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <Header as='h3' style={{ fontSize: '1.5em' }}>April 9, 1944</Header>
            </Grid.Column>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <p style={{ fontSize: '1em' }}>
                Easter Sunday – Received mail.
                </p>
            </Grid.Column>
        </Grid.Row>

        <Grid.Row textAlign='center'>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <Header as='h3' style={{ fontSize: '1.5em' }}>April 11, 1944</Header>
            </Grid.Column>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <p style={{ fontSize: '1em' }}>
                Named his 40 mm gun “Miss Edna”
                </p>
            </Grid.Column>
        </Grid.Row>

        <Grid.Row textAlign='center'>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <Header as='h3' style={{ fontSize: '1.5em' }}>April 13, 1944</Header>
            </Grid.Column>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <p style={{ fontSize: '1em' }}>
                Got under way at 0815.
                </p>
            </Grid.Column>
        </Grid.Row>

        <Grid.Row textAlign='center'>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <Header as='h3' style={{ fontSize: '1.5em' }}>April 16, 1944</Header>
            </Grid.Column>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <p style={{ fontSize: '1em' }}>
                Marshall is about 250 miles from Rabaul.
                </p>
            </Grid.Column>
        </Grid.Row>

        <Grid.Row textAlign='center'>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <Header as='h3' style={{ fontSize: '1.5em' }}>April 19, 1944</Header>
            </Grid.Column>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <p style={{ fontSize: '1em' }}>
                Refueled at 0600 from USS San Francisco (CA-38).  "Captain announces we are going to attack Hollandia."
                </p>
            </Grid.Column>
        </Grid.Row>

        <Grid.Row textAlign='center'>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <Header as='h3' style={{ fontSize: '1.5em' }}>April 21 - 27, 1944</Header>
            </Grid.Column>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <p style={{ fontSize: '1em' }}>
                Participated in TF 58’s strikes against Hollandia in New Guinea.  Planes took off at 0530.  Destroyed about 12 planes on the ground and 14 barges and small craft were sunk.
                </p>
            </Grid.Column>
        </Grid.Row>

        <Grid.Row textAlign='center'>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <Header as='h3' style={{ fontSize: '1.5em' }}>April 22, 1944</Header>
            </Grid.Column>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <p style={{ fontSize: '1em' }}>
                American planes took off at 0555, first plane crashed into the foremast of the USS New Orleans (CA-32).  Troops landed and took the first objective by 1300.  Had trouble with the second objective.
                </p>
            </Grid.Column>
        </Grid.Row>

        <Grid.Row textAlign='center'>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <Header as='h3' style={{ fontSize: '1.5em' }}>April 23, 1944</Header>
            </Grid.Column>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <p style={{ fontSize: '1em' }}>
                American planes attacked most of the day.
                </p>
            </Grid.Column>
        </Grid.Row>

        <Grid.Row textAlign='center'>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <Header as='h3' style={{ fontSize: '1.5em' }}>April 25, 1944</Header>
            </Grid.Column>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <p style={{ fontSize: '1em' }}>
                The Captain made a speech and congratulated the crew for a job well done.  We are going back to Majuro, but on the way we are going to bomb Truk.
                </p>
            </Grid.Column>
        </Grid.Row>

        <Grid.Row textAlign='center'>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <Header as='h3' style={{ fontSize: '1.5em' }}>April 29, 1944</Header>
            </Grid.Column>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <p style={{ fontSize: '1em' }}>
                Participated in TF 58’s strikes against Truk.  Refueled and received mail.  Some of the old sailors said that that was the first time they ever received mail at sea. Crossed the Dateline.
                </p>
            </Grid.Column>
        </Grid.Row>

        <Grid.Row textAlign='center'>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <Header as='h3' style={{ fontSize: '1.5em' }}>April 29, 1944</Header>
            </Grid.Column>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <p style={{ fontSize: '1em' }}>
                Went to GQ at 0810-attacked by Jap torpedo planes.  The fighters intercepted 15 planes and shot down 12.  The three that got through headed for the USS Yorktown (CV-10), but she shot one of them down.  The San Francisco shot down 1 and a Can got the last one.  The Marshall fired 100 rounds of 5 inch and many rounds of 40’s and 20’s.  At 2325 the Marshall was out on picket duty and 2 torpedo planes attacked.  They fired to torpedos.  One missed the Marshall’s stern by 50 feet.  Marshall fired 150 rounds of 5 inch.
                </p>
            </Grid.Column>
        </Grid.Row>

        <Grid.Row textAlign='center'>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <Header as='h3' style={{ fontSize: '1.5em' }}>May 4, 1944</Header>
            </Grid.Column>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <p style={{ fontSize: '1em' }}>
                Arrived in Majuro at 1145 refueled and received 30 bags of mail.  That night Movie Call.
                </p>
            </Grid.Column>
        </Grid.Row>
        
        <Grid.Row textAlign='center'>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <Header as='h3' style={{ fontSize: '1.5em' }}>May 14, 1944</Header>
            </Grid.Column>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <p style={{ fontSize: '1em' }}>
                Under way at 0710.  Headed to Wake Island and Marcus Island with 3 new carriers, Essex (CV-9), Wasp (CV-7) and San Jacinto (CVL-30).
                </p>
            </Grid.Column>
        </Grid.Row>

        <Grid.Row textAlign='center'>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <Header as='h3' style={{ fontSize: '1.5em' }}>May 15, 1944</Header>
            </Grid.Column>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <p style={{ fontSize: '1em' }}>
                Had Anti aircraft practice and hit 8 sleeves.
                </p>
            </Grid.Column>
        </Grid.Row>

        <Grid.Row textAlign='center'>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <Header as='h3' style={{ fontSize: '1.5em' }}>May 17, 1944</Header>
            </Grid.Column>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <p style={{ fontSize: '1em' }}>
                Steaming just 700 miles from Tokyo.
                </p>
            </Grid.Column>
        </Grid.Row>

        <Grid.Row textAlign='center'>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <Header as='h3' style={{ fontSize: '1.5em' }}>May 18, 1944</Header>
            </Grid.Column>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <p style={{ fontSize: '1em' }}>
                Refueled from the USS Boston (CA-69) then screened the task force so the rest could refuel.  Detached from the Task Force with Hunt, Hancock, Hickok, Reno and San Juan.
                </p>
            </Grid.Column>
        </Grid.Row>

        <Grid.Row textAlign='center'>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <Header as='h3' style={{ fontSize: '1.5em' }}>May 21, 1944</Header>
            </Grid.Column>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <p style={{ fontSize: '1em' }}>
                Rejoined Task Force
                </p>
            </Grid.Column>
        </Grid.Row>

        <Grid.Row textAlign='center'>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <Header as='h3' style={{ fontSize: '1.5em' }}>June 1, 1944</Header>
            </Grid.Column>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <p style={{ fontSize: '1em' }}>
                Moored with other destroyers along side the USS Cascade (AD-16) in Majuro Harbor, Marshall Islands.
                </p>
            </Grid.Column>
        </Grid.Row>

        <Grid.Row textAlign='center'>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <Header as='h3' style={{ fontSize: '1.5em' }}>June 3, 1944</Header>
            </Grid.Column>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <p style={{ fontSize: '1em' }}>
                Underway for a day of training exercises and then return to Majuro Harbor.
                </p>
            </Grid.Column>
        </Grid.Row>

        <Grid.Row textAlign='center'>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <Header as='h3' style={{ fontSize: '1.5em' }}>June 6, 1944</Header>
            </Grid.Column>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <p style={{ fontSize: '1em' }}>
                Underway with USS Bunker Hill (CV-17), Monterey (CVL-26), Wasp (CV-7), Cabot (CVL-28), Iowa (BB-61), and New Jersey (BB-62).
                </p>
            </Grid.Column>
        </Grid.Row>

        <Grid.Row textAlign='center'>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <Header as='h3' style={{ fontSize: '1.5em' }}>June 9, 1944</Header>
            </Grid.Column>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <p style={{ fontSize: '1em' }}>
                Refueled from USS Biloxi (CL-80) 0759 – 0921.
                </p>
            </Grid.Column>
        </Grid.Row>

        <Grid.Row textAlign='center'>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <Header as='h3' style={{ fontSize: '1.5em' }}>June 11, 1944</Header>
            </Grid.Column>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <p style={{ fontSize: '1em' }}>
                Steaming in company with TG 58.2 as a unit of TO 58.2.3 (screen) with USS Lexington (CV-16) and USS Indianapolis (CA-35).  Carriers launched air strikes against Guam and Saipan.
                </p>
            </Grid.Column>
        </Grid.Row>

        <Grid.Row textAlign='center'>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <Header as='h3' style={{ fontSize: '1.5em' }}>June 12, 1944</Header>
            </Grid.Column>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <p style={{ fontSize: '1em' }}>
                Carriers launched air strikes against Guam and Tinian.
                </p>
            </Grid.Column>
        </Grid.Row>

        <Grid.Row textAlign='center'>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <Header as='h3' style={{ fontSize: '1.5em' }}>June 13, 1944</Header>
            </Grid.Column>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <p style={{ fontSize: '1em' }}>
                The Task Force is just 26 miles east of Saipan Island. 
                </p>
            </Grid.Column>
        </Grid.Row>

        <Grid.Row textAlign='center'>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <Header as='h3' style={{ fontSize: '1.5em' }}>June 14, 1944</Header>
            </Grid.Column>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <p style={{ fontSize: '1em' }}>
                Steaming in company with USS Bunker Hill (CV-17), Wasp (CV-7), Monterey (CVK-26), Cabot (CVL-28), Mobile (CL-63), Biloxi (CL-80), Santa Fe(CL-60) and Desron 52, Iowa (BB-61), New Jersey (BB-62) and USS Sullivans (DD-537).  At 0433 Bunker Hill (CV-17) launched air strike against Rota Island.  At 1251 the Marshall refueled from the Iowa (BB-61).
                </p>
            </Grid.Column>
        </Grid.Row>

        <Grid.Row textAlign='center'>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <Header as='h3' style={{ fontSize: '1.5em' }}>June 15, 1944</Header>
            </Grid.Column>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <p style={{ fontSize: '1em' }}>
                Fired on enemy plane 14,000 yards off the port quarter.
                </p>
            </Grid.Column>
        </Grid.Row>

        <Grid.Row textAlign='center'>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <Header as='h3' style={{ fontSize: '1.5em' }}>June 16, 1944</Header>
            </Grid.Column>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <p style={{ fontSize: '1em' }}>
                0653—commenced fueling from USS Platte (AO-24).
                </p>
            </Grid.Column>
        </Grid.Row>

        <Grid.Row textAlign='center'>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <Header as='h3' style={{ fontSize: '1.5em' }}>June 19 - 20, 1944</Header>
            </Grid.Column>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <p style={{ fontSize: '1em' }}>
                <Header as='h2' style={{ fontSize: '1.5em', color:'red'}}>Battle of the Philippine Sea (Marianas Turkey Shoot)</Header> <br/>
                     During the course of this battle the Japanese lost 3 aircraft carriers and 395 carrier based planes.  The Marshall was credited with an assist on downing two planes.  The Marshall was with Admiral Marc Mitscher when 216 planes were returning from a raid on Admiral Ozawa’s fleet.  It was after dark and unable to find the carriers, the planes began to run out of gas and crash into the sea.  At the risk of a submarine attack, Mitcher ordered the Task Force to turn on its lights.
                </p>
            </Grid.Column>
        </Grid.Row>

        <Grid.Row textAlign='center'>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <Header as='h3' style={{ fontSize: '1.5em' }}>June 19, 1944</Header>
            </Grid.Column>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <p style={{ fontSize: '1em' }}>
                0541 - Enemy aircraft reported at a distance of 25 miles, went to general quarters.  <br/> 0637 - Secured from general quarters.  <br/> 1014-Large group of enemy aircraft reported at 114 miles and closing on task force.  <br/> 1022 - Went to general quarters.  <br/> 1112 - Enemy aircraft attacking other task groups near our formation.  <br/> 1451 - Secured from general quarters.  <br/> 1817 - Enemy aircraft reported closing our formation went to general quarters. <br/>  1853 - Secured from general quarters as enemy planes turned away from our formation.  During day’s operations, Marshall assisted in shooting down two enemy planes over the formation.
                </p>
            </Grid.Column>
        </Grid.Row>

        <Grid.Row textAlign='center'>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <Header as='h3' style={{ fontSize: '1.5em' }}>June 20, 1944</Header>
            </Grid.Column>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <p style={{ fontSize: '1em' }}>
                1545 - Ctf 58 received contact report of Japanese Fleet, bearing 290 degrees distant 215 miles.  All carriers ordered to prepare to launch planes for strike.  <br/> 1627 - Carriers launched aircraft for strike against Japanese Task Force bearing 280 degrees, distant 250 miles.  <br/>2140 - Maneuvered at various courses and speeds to recover crash survivors.  <br/>  2150 - Stopped to recover 3 survivors from the USS San Jacinto (CVL-39).
                </p>
            </Grid.Column>
        </Grid.Row>

        <Grid.Row textAlign='center'>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <Header as='h3' style={{ fontSize: '1.5em' }}>June 21, 1944</Header>
            </Grid.Column>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <p style={{ fontSize: '1em' }}>
                0932 - Recovered 2 airmen from USS Bunker Hill (CV-17).
                </p>
            </Grid.Column>
        </Grid.Row>
                
        <Grid.Row textAlign='center'>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <Header as='h3' style={{ fontSize: '1.5em' }}>June 23, 1944</Header>
            </Grid.Column>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <p style={{ fontSize: '1em' }}>
                Steaming with CTF (carrier task force) 58 with USS Lexington (CV-16).  <br/> 1205 - General quarters as enemy planes reported in the area.  <br/> 1224 - Secured from general quarters.
                </p>
            </Grid.Column>
        </Grid.Row>

        <Grid.Row textAlign='center'>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <Header as='h3' style={{ fontSize: '1.5em' }}>June 25, 1944</Header>
            </Grid.Column>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <p style={{ fontSize: '1em' }}>
                Steaming in company with TG 58.2 composed of Bunker hill (CV-17), Wasp (CV-7), Cabot (CVL-28), Monterey CVL-26) and South Dakota (BB-57).
                </p>
            </Grid.Column>
        </Grid.Row>

        <Grid.Row textAlign='center'>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <Header as='h3' style={{ fontSize: '1.5em' }}>June 27, 1944</Header>
            </Grid.Column>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <p style={{ fontSize: '1em' }}>
                1048 - Moored in Eniwetok next to Arethusa (IX-135) (tanker) to receive Navy special fuel oil.  <br/> 1225-completed refueling.  <br/>1234 - underway to port side USS Neshavic (AO-71) to receive diesel oil.  <br/> 1340 - completed refueling and anchored at berth 578 Eniwetok Harbor, Marshall Islands.
                </p>
            </Grid.Column>
        </Grid.Row>
        
        <Grid.Row textAlign='center'>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <Header as='h3' style={{ fontSize: '1.5em' }}>June 30, 1944</Header>
            </Grid.Column>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <p style={{ fontSize: '1em' }}>
                0702 - Underway.
                </p>
            </Grid.Column>
        </Grid.Row>

        <Grid.Row textAlign='center'>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <Header as='h3' style={{ fontSize: '1.5em' }}>July 4, 1944</Header>
            </Grid.Column>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <p style={{ fontSize: '1em' }}>
                Participated in the strikes against Chichi Jima and Iwo Jima.
                </p>
            </Grid.Column>
        </Grid.Row>

        <Grid.Row textAlign='center'>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <Header as='h3' style={{ fontSize: '1.5em' }}>July 13, 1944</Header>
            </Grid.Column>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <p style={{ fontSize: '1em' }}>
                Formed screen for 10 ships.  Marshall assigned to screening station #9.  <br/> 1122 - Commenced refueling with USS Pecos (AO-65).
                </p>
            </Grid.Column>
        </Grid.Row>

        <Grid.Row textAlign='center'>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <Header as='h3' style={{ fontSize: '1.5em' }}>July 14, 1944</Header>
            </Grid.Column>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <p style={{ fontSize: '1em' }}>
                Wasp (CV-7) and Monterey (CVL-26) commenced launching air strikes against Guam.  1540-Franklin (CV-13) and Monterey (CVL-26) commenced flight operations.  1816-Hickox (DD-673) arrived alongside for passing mail.
                </p>
            </Grid.Column>
        </Grid.Row>

        <Grid.Row textAlign='center'>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <Header as='h3' style={{ fontSize: '1.5em' }}>July 15, 1944</Header>
            </Grid.Column>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <p style={{ fontSize: '1em' }}>
                0600 - Formation commenced refueling operations. Screen rotating revolving to cover destroyers involved.  <br/>0980 - Carriers commenced flight operations.
                </p>
            </Grid.Column>
        </Grid.Row>

        <Grid.Row textAlign='center'>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <Header as='h3' style={{ fontSize: '1.5em' }}>July 17, 1944</Header>
            </Grid.Column>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <p style={{ fontSize: '1em' }}>
                1217 - Formation commenced scheduled fueling operations. <br/> 1546 - Arrived portside of USS Schuylkill (AO-76) for refueling.
                </p>
            </Grid.Column>
        </Grid.Row>

        <Grid.Row textAlign='center'>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <Header as='h3' style={{ fontSize: '1.5em' }}>July 18, 1944</Header>
            </Grid.Column>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <p style={{ fontSize: '1em' }}>
                Steaming with TG 58.2 composed of Wasp (CV-7), Franklin (CV-13), Monterey (CVL-26), Boston (CA-69), Canberra (CA-70), San Juan (CL-54) and DesRon 52 at a speed of 22 knots.  Marshall (DD-676) on picket station 10 miles ahead of formation.  <br/>0846 - Received mail from Hancock.  <br/>1420 - Marshall commenced test firing of automatic weapons.  <br/>1433 - Secured from drill.
                </p>
            </Grid.Column>
        </Grid.Row>

        <Grid.Row textAlign='center'>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <Header as='h3' style={{ fontSize: '1.5em' }}>July 19, 1944</Header>
            </Grid.Column>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <p style={{ fontSize: '1em' }}>
                1356 - All carriers commenced flight operations for strikes on Guam.
                </p>
            </Grid.Column>
        </Grid.Row>

        <Grid.Row textAlign='center'>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <Header as='h3' style={{ fontSize: '1.5em' }}>July 21, 1944</Header>
            </Grid.Column>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <p style={{ fontSize: '1em' }}>
                0527 - Carriers commenced launching planes for strikes on Guam.
                </p>
            </Grid.Column>
        </Grid.Row>

        <Grid.Row textAlign='center'>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <Header as='h3' style={{ fontSize: '1.5em' }}>July 22, 1944</Header>
            </Grid.Column>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <p style={{ fontSize: '1em' }}>
                0831 - Exercised at general quarters for drill.  <br/>0904 - Secured from general quarters.  <br/>0957 - Arrived along side of USS Franklin (CV-13) to transfer SC1 Paulson with acute appendicitis.  <br/>1902 - Anchored in Garapan Roads, Saipan Island, Marinas islands with various units of the U.S. Pacific fleet.  Ship darkened.  Gunfire and illumination observed on Saipan Island through watch.
                </p>
            </Grid.Column>
        </Grid.Row>

        <Grid.Row textAlign='center'>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <Header as='h3' style={{ fontSize: '1.5em' }}>July 23, 1944</Header>
            </Grid.Column>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <p style={{ fontSize: '1em' }}>
                0625 - Underway. <br/>1929 - Commenced refueling from USS Tappahannoch (AO-43).
                </p>
            </Grid.Column>
        </Grid.Row>

        <Grid.Row textAlign='center'>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <Header as='h3' style={{ fontSize: '1.5em' }}>July 24, 1944</Header>
            </Grid.Column>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <p style={{ fontSize: '1em' }}>
                1007 - Received mail from USS Owen (DD-536).
                </p>
            </Grid.Column>
        </Grid.Row>

        <Grid.Row textAlign='center'>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <Header as='h3' style={{ fontSize: '1.5em' }}>July 25, 1944</Header>
            </Grid.Column>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <p style={{ fontSize: '1em' }}>
                0646 - Arrived alongside USS Canberra (CA-70) for refueling and transfer of S1c Queen who has an acute appendicitis. <br/> 1030 - Received mail from USS Tingey (DD-539).  Carriers commence air strikes against Palau.
                </p>
            </Grid.Column>
        </Grid.Row>

        <Grid.Row textAlign='center'>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <Header as='h3' style={{ fontSize: '1.5em' }}>July 26, 1944</Header>
            </Grid.Column>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <p style={{ fontSize: '1em' }}>
                Wasp and Franklin commenced air strikes against Koror Island.  
                </p>
            </Grid.Column>
        </Grid.Row>

        <Grid.Row textAlign='center'>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <Header as='h3' style={{ fontSize: '1.5em' }}>July 27, 1944</Header>
            </Grid.Column>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <p style={{ fontSize: '1em' }}>
                1020 - Plane crashed abeam to port: distant 3000 yards. <br/> 1025 - Stopped to recover two survivors from USS Franklin (CV-13).
                </p>
            </Grid.Column>
        </Grid.Row>

        <Grid.Row textAlign='center'>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <Header as='h3' style={{ fontSize: '1.5em' }}>July 28, 1944</Header>
            </Grid.Column>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <p style={{ fontSize: '1em' }}>
                0723 - Commenced refueling from USS Indiana (BB-58).
                </p>
            </Grid.Column>
        </Grid.Row>

        <Grid.Row textAlign='center'>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <Header as='h3' style={{ fontSize: '1.5em' }}>July 29, 1944</Header>
            </Grid.Column>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <p style={{ fontSize: '1em' }}>
                0710 - Arrived alongside the USS Franklin (CV-13). <br/> 0713 - Received mail and SC1c Paulson.  <br/>0752 - Arrived alongside the USS Wasp (CV-7) for transfer of personnel.  <br/>1047 - Transferred mail to USS Hunt (DD-674).  <br/>1626 - Arrived alongside starboard quarter of USS San Juan (CL-54) for transfer of personnel.  <br/>1755 - USS Yorktown relieved USS Franklin.
                </p>
            </Grid.Column>
        </Grid.Row>

        <Grid.Row textAlign='center'>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <Header as='h3' style={{ fontSize: '1.5em' }}>July 32, 1944</Header>
            </Grid.Column>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <p style={{ fontSize: '1em' }}>
                Steaming in company with TG 58.4 consisting of Essex, Langley, Iowa, New Jersey, Indiana, Alabama, San Diego and DesRon 52.
                </p>
            </Grid.Column>
        </Grid.Row>

        <Grid.Row textAlign='center'>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <Header as='h3' style={{ fontSize: '1.5em' }}>August 1, 1944</Header>
            </Grid.Column>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <p style={{ fontSize: '1em' }}>
                1736 - Arrived along side the USS Essex (CV-9) to receive mail.
                </p>
            </Grid.Column>
        </Grid.Row>

        <Grid.Row textAlign='center'>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <Header as='h3' style={{ fontSize: '1.5em' }}>August 4, 1944</Header>
            </Grid.Column>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <p style={{ fontSize: '1em' }}>
                Arrived alongside USS Alabama (BB-50) and received 100,998 gallons of fuel.
                </p>
            </Grid.Column>
        </Grid.Row>

        <Grid.Row textAlign='center'>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <Header as='h3' style={{ fontSize: '1.5em' }}>August 6, 1944</Header>
            </Grid.Column>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <p style={{ fontSize: '1em' }}>
                1349 - Received mail from USS Miller (DD-535).
                </p>
            </Grid.Column>
        </Grid.Row>

        <Grid.Row textAlign='center'>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <Header as='h3' style={{ fontSize: '1.5em' }}>August 7, 1944</Header>
            </Grid.Column>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <p style={{ fontSize: '1em' }}>
                Steaming in company with Iowa (BB-61), Washington (BB-56), Indiana (BB-58), Birmingham (CL-62), The Sullivans (DD-537) and DesDiv 103.  <br/>1053 - Arrived starboard side of USS Pecos (AO-65) for refueling.
                </p>
            </Grid.Column>
        </Grid.Row>

        <Grid.Row textAlign='center'>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <Header as='h3' style={{ fontSize: '1.5em' }}>August 8, 1944</Header>
            </Grid.Column>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <p style={{ fontSize: '1em' }}>
                2054 - Exercised at General Quarters for drill.
                </p>
            </Grid.Column>
        </Grid.Row>

        <Grid.Row textAlign='center'>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <Header as='h3' style={{ fontSize: '1.5em' }}>August 9, 1944</Header>
            </Grid.Column>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <p style={{ fontSize: '1em' }}>
                1420 - Commenced test firing 5” guns.
                </p>
            </Grid.Column>
        </Grid.Row>

        <Grid.Row textAlign='center'>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <Header as='h3' style={{ fontSize: '1.5em' }}>August 11, 1944</Header>
            </Grid.Column>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <p style={{ fontSize: '1em' }}>
                1220 - Entered port-Eniwetok, Marshall Islands.  Moored beside USS Signal (ID-1532) for refueling.
                </p>
            </Grid.Column>
        </Grid.Row>

        <Grid.Row textAlign='center'>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <Header as='h3' style={{ fontSize: '1.5em' }}>August 12, 1944</Header>
            </Grid.Column>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <p style={{ fontSize: '1em' }}>
                Moored as before.
                </p>
            </Grid.Column>
        </Grid.Row>

        <Grid.Row textAlign='center'>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <Header as='h3' style={{ fontSize: '1.5em' }}>August 19, 1944</Header>
            </Grid.Column>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <p style={{ fontSize: '1em' }}>
                Moored as before.  <br/>1150 - Commenced pumping fuel to USS Piedmont (AD-17).  Pumped 53,000 gallons to USS Piedmont (AD-17).  <br/>2227 - Air flash “red”.  All hands to General Quarters.  <br/>2248 - Air flash “white”.  Secured from GQ.
                </p>
            </Grid.Column>
        </Grid.Row>

        <Grid.Row textAlign='center'>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <Header as='h3' style={{ fontSize: '1.5em' }}>August 20, 1944</Header>
            </Grid.Column>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <p style={{ fontSize: '1em' }}>
                Moored as before.  <br/>1035 - Commenced delivering fuel oil to USS Owen (DD-776).
                </p>
            </Grid.Column>
        </Grid.Row>

        <Grid.Row textAlign='center'>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <Header as='h3' style={{ fontSize: '1.5em' }}>August 22, 1944</Header>
            </Grid.Column>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <p style={{ fontSize: '1em' }}>
                Moored as before <br/>1005 - Copmmenced fueling from USS Piedmont (AD-17).
                </p>
            </Grid.Column>
        </Grid.Row>

        <Grid.Row textAlign='center'>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <Header as='h3' style={{ fontSize: '1.5em' }}>August 23, 1944</Header>
            </Grid.Column>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <p style={{ fontSize: '1em' }}>
                Moored as before.  <br/>0628 - Underway.  <br/>1330 - Commenced scheduled drone firing exercise.  <br/>1550 - Commenced AA firing at towed sleeve.
                </p>
            </Grid.Column>
        </Grid.Row>

        <Grid.Row textAlign='center'>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <Header as='h3' style={{ fontSize: '1.5em' }}>August 24, 1944</Header>
            </Grid.Column>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <p style={{ fontSize: '1em' }}>
                Steaming in company with Franklin (CV-13), San Jacinto (CVL-30), Belleauwood (CVL-24), Biloxi (CL-80), New Orleans (CA-32) and DesDiv 11.  <br/>0707 - Ship exercised at General Quarters to repel simulated air attack during special exercise “6D”.  0921-Went to General Quarters for drill firing.  Secured from GQ having expended 24 rounds of 5” ammunition.  <br/>1045 - Commenced exercise “13A”.  <br/>1113 - Completed exercise having expended 16 rounds of 5” and 20 rounds of 40mm.  <br/>1717 - Moored port side USS Platte (AO-24) for fuel.  <br/>1843 - Moored starboard side USS Farenholt (DD-491) in Eniwetok Harbor.
                </p>
            </Grid.Column>
        </Grid.Row>

        <Grid.Row textAlign='center'>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <Header as='h3' style={{ fontSize: '1.5em' }}>August 25, 1944</Header>
            </Grid.Column>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <p style={{ fontSize: '1em' }}>
                Moored Eniwetok Harbor, Marshall Islands. <br/> 0530 - Underway.  <br/>0900 - Ship exercised GQ for “AA” firing at towed sleeve.  <br/>0930 - Sonar contact 060 degrees at 1400 yards.  Set course to investigate. <br/> 0950 - Contact negative.  1030 - Ceased “AA” exercise.  <br/>1500 - Exercised at general quarters.  <br/>1520 - Cancelled gunnery exercise due to casualty on target.
                </p>
            </Grid.Column>
        </Grid.Row>

        <Grid.Row textAlign='center'>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <Header as='h3' style={{ fontSize: '1.5em' }}>August 26, 1944</Header>
            </Grid.Column>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <p style={{ fontSize: '1em' }}>
                0934 - Maneuvering to rescue personnel in plane crash astern of Bunker Hill (CV-17). <br/> 0944 - Picked up pilot from USS Intrepid (CV-11).  <br/>1640 - Moored to starboard side USS Manileno (IX-141) for fuel.
                </p>
            </Grid.Column>
        </Grid.Row>

        <Grid.Row textAlign='center'>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <Header as='h3' style={{ fontSize: '1.5em' }}>August 27, 1944</Header>
            </Grid.Column>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <p style={{ fontSize: '1em' }}>
                1325 - Entered floating dry-dock, ARD 23. <br/> 1345 - Secured main engines and other machinery.  <br/>1347 - Commenced pumping out dry-dock.<br/>  1425 - Grounded on keel blocks.
                </p>
            </Grid.Column>
        </Grid.Row>

        <Grid.Row textAlign='center'>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <Header as='h3' style={{ fontSize: '1.5em' }}>August 28, 1944</Header>
            </Grid.Column>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <p style={{ fontSize: '1em' }}>
                Grounded on keel blocks as before.  The Hull Board inspected the underwater hull fittings, shafting, and rudder.  Conditions satisfactory except for missing rope guards.  <br/> 0920 - Commenced flooding the dry-dock.  <br/> 1008 - Cleared keel blocks.  <br/> 1201 - Moored port side to USS Melvin (AO-338) to receive 100,847 gallons of fuel.  <br/> 1414 - Anchored in 20 fathoms of water.
                </p>
            </Grid.Column>
        </Grid.Row>

        <Grid.Row textAlign='center'>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <Header as='h3' style={{ fontSize: '1.5em' }}>September 6 - 24, 1944</Header>
            </Grid.Column>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <p style={{ fontSize: '1em' }}>
                Took part in the Palau and Phillippine operations
                </p>
            </Grid.Column>
        </Grid.Row>

        <Grid.Row textAlign='center'>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <Header as='h3' style={{ fontSize: '1.5em' }}>September 12, 1944</Header>
            </Grid.Column>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <p style={{ fontSize: '1em' }}>
                0840 - Commenced maneuvering on various courses and at various speeds to investigate small boat bearing 320 degrees at 15 miles from Task Group 38.2.  <br/>0858 - Captured and picked up 44 Japanese survivors from the Cruiser Natori, sunk 18 August by Hardhead (SS-365).  Boat was sunk by gunfire: 350 rounds of 40 mm and 240 rounds of 20 mm expended.   <br/>1100-Arrived alongside USS New Jersey (BB-62) (Com3rdFlt).   <br/>1115 - Transferred all documents and gear along with 2 of the most intelligent looking prisoners.   <br/>1118-All lines clear.  <br/> 1150 - received mail from USS Sullivans (DD-537).  <br/>1653 - Alongside Bunker Hill. (CV-17) and commenced transfer of prisoners.
                </p>
                <img src={japaneseSurvivors} class="articlePhoto" alt="Japanese survivors from the Cruiser Natori" />
            </Grid.Column>
        </Grid.Row>

        <Grid.Row textAlign='center'>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <Header as='h3' style={{ fontSize: '1.5em' }}>September 13, 1944</Header>
            </Grid.Column>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <p style={{ fontSize: '1em' }}>
                0530 - Uunidentified planes reported to westward, exercised at precautionary general quarters.   <br/>0606 - Commenced carrier operations for air strikes on Leyete, Luzon and Samar.  <br/> 0622 - All unidentified planes clear of area; secured from general quarters.
                </p>
            </Grid.Column>
        </Grid.Row>

        <Grid.Row textAlign='center'>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <Header as='h3' style={{ fontSize: '1.5em' }}>September 16, 1944</Header>
            </Grid.Column>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <p style={{ fontSize: '1em' }}>
                0820-Arrived along starboard side of Saugatuck and commenced fueling.  <br/>1003-Arrived alongside USS Owen (DD-536) to pass mail. <br/> 1110-Arrived along side of USS Mississinewa (AO-59). To receive mail for TG 38.2.  <br/>1301-Mail passing completed.  <br/>1350-Passed mail to New Jersey. <br/>1416-Passed mail to Iowa (BB-61).  <br/>1505-Passed mail to Hancock (-19).  <br/>1524-Passed mail to Hunt (DD-674).  <br/>1549-Passed mail to Wedderburn.  <br/>1615-Passed mail to Sullivans.
                </p>
            </Grid.Column>
        </Grid.Row>

        <Grid.Row textAlign='center'>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <Header as='h3' style={{ fontSize: '1.5em' }}>September 17, 1944</Header>
            </Grid.Column>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <p style={{ fontSize: '1em' }}>
                1004 - Sighted Peleliu Island bearing 083 degrees, distant 25 miles.
                </p>
            </Grid.Column>
        </Grid.Row>

        <Grid.Row textAlign='center'>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <Header as='h3' style={{ fontSize: '1.5em' }}>September 21, 1944</Header>
            </Grid.Column>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <p style={{ fontSize: '1em' }}>
                0759 - Carriers commenced flight operations for strikes against Clark Field, Nichols Field and Manila Bay. 
                </p>
            </Grid.Column>
        </Grid.Row>

        <Grid.Row textAlign='center'>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <Header as='h3' style={{ fontSize: '1.5em' }}>September 22, 1944</Header>
            </Grid.Column>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <p style={{ fontSize: '1em' }}>
                Operating in area off East Coast of Luzon Island, Philippine Islands, while conducting fighter sweeps and air strikes against air fields and shipping in vicinity of Manila Bay.   <br/>0550 - Went to general quarters to repel threatened air attack.   <br/>0641 - Secured from general quarters.   <br/>0745 - Went to general quarters to repel threatened air attack.   <br/>0821 - All enemy planes clear of area, secured from general quarters.
                </p>
            </Grid.Column>
        </Grid.Row>

        <Grid.Row textAlign='center'>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <Header as='h3' style={{ fontSize: '1.5em' }}>September 26, 1944</Header>
            </Grid.Column>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <p style={{ fontSize: '1em' }}>
                Steaming in company with Task Force 34 composed of New Jersey (ComThirdFlt-Admiral Halsey), Washington, Massachusetts, Alabama, CruDiv 14 and DesRon 52.
                </p>
            </Grid.Column>
        </Grid.Row>

        <Grid.Row textAlign='center'>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <Header as='h3' style={{ fontSize: '1.5em' }}>September 27, 1944</Header>
            </Grid.Column>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <p style={{ fontSize: '1em' }}>
                0854 - Went alongside starboard side of USS Enoree (AO-69) to receive fuel.  <br/>1340 - Commenced training exercises.  Conducting 20 mm and 40 mm firing at towed sleeve and test fire of Mk 32 fuzed 5” projectiles.
                </p>
            </Grid.Column>
        </Grid.Row>

        <Grid.Row textAlign='center'>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <Header as='h3' style={{ fontSize: '1.5em' }}>September 28, 1944</Header>
            </Grid.Column>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <p style={{ fontSize: '1em' }}>
                0525 - Ships of Task Force 34 proceeding independently to enter Garapan Roads, Saipan Island.  DesDiv 104 formed screen 53 on BatDiv 9.  Marshall commenced patrolling sector at 12 knots.
                </p>
            </Grid.Column>
        </Grid.Row>

        <Grid.Row textAlign='center'>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <Header as='h3' style={{ fontSize: '1.5em' }}>September 29, 1944</Header>
            </Grid.Column>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <p style={{ fontSize: '1em' }}>
                1407 - Anchored in berth H-38, Garapan Island, Saipan Island.  <br/>1553 - Underway
                </p>
            </Grid.Column>
        </Grid.Row>

        <Grid.Row textAlign='center'>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <Header as='h3' style={{ fontSize: '1.5em' }}>October 1, 1944</Header>
            </Grid.Column>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <p style={{ fontSize: '1em' }}>
                0856 - Anchored in berth #130 in 22 fathoms of water in Ulithi Harbor, Western Caroline Islands.
                </p>
            </Grid.Column>
        </Grid.Row>

        <Grid.Row textAlign='center'>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <Header as='h3' style={{ fontSize: '1.5em' }}>October 3, 1944</Header>
            </Grid.Column>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <p style={{ fontSize: '1em' }}>
                0554 - Underway
                </p>
            </Grid.Column>
        </Grid.Row>

        <Grid.Row textAlign='center'>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <Header as='h3' style={{ fontSize: '1.5em' }}>October 4, 1944</Header>
            </Grid.Column>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <p style={{ fontSize: '1em' }}>
                1050 - Moored starboard side to USS Cimarron (AO-22) for fuel.  <br/>1225 - Underway for berth #130.  <br/>1228 - Anchored in berth #130, Ulithi Harbor.
                </p>
            </Grid.Column>
        </Grid.Row>

        <Grid.Row textAlign='center'>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <Header as='h3' style={{ fontSize: '1.5em' }}>October 5, 1944</Header>
            </Grid.Column>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <p style={{ fontSize: '1em' }}>
                1106 - Moored starboard side to USS Alabama (BB-60) for provisioning ship.  Received 223 rounds of 5” 38 projectiles. <br/> 1635 - Underway.  <br/>1740 - Relieved USS Stephen Potter at patrol station 2.
                </p>
            </Grid.Column>
        </Grid.Row>

        <Grid.Row textAlign='center'>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <Header as='h3' style={{ fontSize: '1.5em' }}>October 6, 1944</Header>
            </Grid.Column>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <p style={{ fontSize: '1em' }}>
                Marshall’s task group got underway.
                </p>
            </Grid.Column>
        </Grid.Row>

        <Grid.Row textAlign='center'>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <Header as='h3' style={{ fontSize: '1.5em' }}>October 10, 1944</Header>
            </Grid.Column>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <p style={{ fontSize: '1em' }}>
                Task group sent strikes against Okinawa.
                </p>
            </Grid.Column>
        </Grid.Row>

        <Grid.Row textAlign='center'>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <Header as='h3' style={{ fontSize: '1.5em' }}>October 12 - 14, 1944</Header>
            </Grid.Column>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <p style={{ fontSize: '1em' }}>
                Task group sent strikes against Formosa.
                </p>
            </Grid.Column>
        </Grid.Row>

        <Grid.Row textAlign='center'>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <Header as='h3' style={{ fontSize: '1.5em' }}>October 14, 1944</Header>
            </Grid.Column>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <p style={{ fontSize: '1em' }}>
                Marshall provided antiaircraft cover for damaged cruiser USS Canberra (CA-70).
                </p>
            </Grid.Column>
        </Grid.Row>

        <Grid.Row textAlign='center'>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <Header as='h3' style={{ fontSize: '1.5em' }}>October 23 - 27, 1944</Header>
            </Grid.Column>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <p style={{ fontSize: '1em' }}>
                <Header as='h2' style={{ fontSize: '1.5em', color:'red'}}>Battle of Leyte Gulf</Header>
                USS Marshall sailing in Sibuyan Sea -
                <br/>The Battle of Leyte Gulf was the largest naval battle ever fought.  The Marshall was part of the destroyer screen that protected Admiral William F. Halsey on the battleship New Jersey.  Admiral Halsey ordered his fleet of 17 Aircraft Carriers, 6 Battleships, 17 Cruisers and 64 Destroyers to steam North in pursuit of Admiral Ozawa’s fleet consisting of 7 Aircraft Carriers.  In this battle, the Japanese lost 4 Carriers, 3 Battleships, 10 Cruisers and 9 Destroyers.  After this battle the Japanese Navy was no longer was an effective fighting force.
                </p>
            </Grid.Column>
        </Grid.Row>

        <Grid.Row textAlign='center'>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <Header as='h3' style={{ fontSize: '1.5em' }}>October 24, 1944</Header>
            </Grid.Column>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <p style={{ fontSize: '1em' }}>
                Steaming in company with Intrepid (CV-11), New Jersey (BB-62), Vincennes (CA-44), Biloxi (CL-80) and Miami (CL-89).  <br/>  0130 - Unidentified aircraft closing on bearing 270 degrees.  Independence (CVL-22) launched night fighter.  Cushing fired on plane.  <br/> 0215 - Enemy plane being chased by night fighters.  <br/> 0227 - One enemy plane splashed by night fighter. <br/>  0335 - One Mavis splashed by night fighter. <br/> 1806 - USS Independence (CVL-22) leaving formation with Yarnell (DD-541) and Stockham (DD-683) to conduct independent searches for enemy forces. <br/>  1952 - Ship went to General Quarters, enemy planes reported in area.  <br/> 2123 - All clear; secured from General Quarters.
                </p>
            </Grid.Column>
        </Grid.Row>

        <Grid.Row textAlign='center'>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <Header as='h3' style={{ fontSize: '1.5em' }}>October 25, 1944</Header>
            </Grid.Column>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <p style={{ fontSize: '1em' }}>
                <br/> 0400 - USS Marshall joined TF-34.5 <br/> 1055 - TF-34.5 released by Halsey to intercept Kurita's Center Force. <br/>  Steaming with USS Massachusetts (BB-59) USS Vincennes (CA-44), USS Miami (CVL-28), USS Biloxi (CL-80), USS Mobile (CL-63), USS New Orleans (CA-32), USS Santa Fe (CL-60) and USS Wichita (CA-45).  <br/>0259 -  Maneuvering at various courses and speeds in obedience to signal to form T.F.34. <br/> 0756 - Changed speed to 20 knots. <br/>  1118 - All units join their respective Task Groups.  Proceeding to join T.G. 38.2
                </p>
            </Grid.Column>
        </Grid.Row>

        <Grid.Row textAlign='center'>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <Header as='h3' style={{ fontSize: '1.5em' }}>October 26, 1944</Header>
            </Grid.Column>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <p style={{ fontSize: '1em' }}>
                0042 - CruDiv 14 and DesDiv 103 proceeding to investigate contact reported by Hancock (CV-19).  <br/>0130 - Crusiers and Destroyers returning after having sunk target believed to be Atago class cruiser.  <br/>0620 - Passing many Japanese floating in water.
                </p>
            </Grid.Column>
        </Grid.Row>

        <Grid.Row textAlign='center'>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <Header as='h3' style={{ fontSize: '1.5em' }}>October 27, 1944</Header>
            </Grid.Column>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <p style={{ fontSize: '1em' }}>
                0909 - Received fuel alongside USS Neosho (AO-48).
                </p>
            </Grid.Column>
        </Grid.Row>

        <Grid.Row textAlign='center'>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <Header as='h3' style={{ fontSize: '1.5em' }}>October 29, 1944</Header>
            </Grid.Column>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <p style={{ fontSize: '1em' }}>
                1156 - Ship went to General Quarters, enemy planes approaching formation.  <br/>1235 - Secured from General Quarters.  <br/>1330 - Went to General Quarters to repel threatened air attack from the northwest. <br/> 1343 - All clear; secure from General Quarters.
                </p>
            </Grid.Column>
        </Grid.Row>

        <Grid.Row textAlign='center'>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <Header as='h3' style={{ fontSize: '1.5em' }}>November 27, 1944</Header>
            </Grid.Column>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <p style={{ fontSize: '1em' }}>
                1745 - Entered Ulithi harbor and proceeded to refuel alongside USS Monongahela (AO-42).
                </p>
            </Grid.Column>
        </Grid.Row>

        <Grid.Row textAlign='center'>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <Header as='h3' style={{ fontSize: '1.5em' }}>November 28, 1944</Header>
            </Grid.Column>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <p style={{ fontSize: '1em' }}>
                Moored starboard side of USS Kalk (DD-611) in berth #9, Ulithi Atoll, Caroline Islands.
                </p>
            </Grid.Column>
        </Grid.Row>

        <Grid.Row textAlign='center'>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <Header as='h3' style={{ fontSize: '1.5em' }}>November 29, 1944</Header>
            </Grid.Column>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <p style={{ fontSize: '1em' }}>
                Transferred off Marshall to USS ITREPID (CV-11) for transportation to NTC, Treasure Island, San Francisco for Gunner’s Mates and Electric Hydraulic School.
                </p>
            </Grid.Column>
        </Grid.Row>

        <Grid.Row textAlign='center'>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <Header as='h3' style={{ fontSize: '1.5em' }}>December 20, 1944</Header>
            </Grid.Column>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <p style={{ fontSize: '1em' }}>
                Reported aboard NTS, Treasure Island, San Francisco.
                </p>
            </Grid.Column>
        </Grid.Row>

        <Grid.Row textAlign='center'>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <Header as='h3' style={{ fontSize: '1.5em' }}>January 25 - May 5, 1945</Header>
            </Grid.Column>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <p style={{ fontSize: '1em' }}>
                Reported aboard US NAV REP BASE, San Diego, California for Gunnery School.
                </p>
            </Grid.Column>
        </Grid.Row>

        <Grid.Row textAlign='center'>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <Header as='h3' style={{ fontSize: '1.5em' }}>May 5 - 12, 1945</Header>
            </Grid.Column>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <p style={{ fontSize: '1em' }}>
                On leave.
                </p>
            </Grid.Column>
        </Grid.Row>

        <Grid.Row textAlign='center'>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <Header as='h3' style={{ fontSize: '1.5em' }}>May 17, 1945</Header>
            </Grid.Column>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <p style={{ fontSize: '1em' }}>
                U.S. RecShip, San Francisco for pre-commission duty for USS Cohocton (AO-101).
                </p>
            </Grid.Column>
        </Grid.Row>

        <Grid.Row textAlign='center'>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <Header as='h3' style={{ fontSize: '1.5em' }}>August 25, 1945</Header>
            </Grid.Column>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <p style={{ fontSize: '1em' }}>
                Reported aboard USS Cohocton (AO-101).
                </p>
                    <img src={cohocton} class="articlePhoto" alt="USS Cohocton (AO-101) in 1945" />
            </Grid.Column>
        </Grid.Row>

        <Grid.Row textAlign='center'>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <Header as='h3' style={{ fontSize: '1.5em' }}>September 5, 1945</Header>
            </Grid.Column>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <p style={{ fontSize: '1em' }}>
                Departed for the Western Pacific.
                </p>
            </Grid.Column>
        </Grid.Row>

        <Grid.Row textAlign='center'>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <Header as='h3' style={{ fontSize: '1.5em' }}>September 18, 1945</Header>
            </Grid.Column>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <p style={{ fontSize: '1em' }}>
                The Cohocton arrived at Midway.
                </p>
            </Grid.Column>
        </Grid.Row>

        <Grid.Row textAlign='center'>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <Header as='h3' style={{ fontSize: '1.5em' }}>September 19, 1945</Header>
            </Grid.Column>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <p style={{ fontSize: '1em' }}>
                Clyde departed the USS Cohocton for transfer to out-processing in the United States.
                </p>
            </Grid.Column>
        </Grid.Row>

        <Grid.Row textAlign='center'>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <Header as='h3' style={{ fontSize: '1.5em' }}>November 8, 1945</Header>
            </Grid.Column>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <p style={{ fontSize: '1em' }}>
                Arrived at USNRS Galveston, Texas – awaiting transportation.
                </p>
            </Grid.Column>
        </Grid.Row>

        <Grid.Row textAlign='center'>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <Header as='h3' style={{ fontSize: '1.5em' }}>November 19, 1945</Header>
            </Grid.Column>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
                <p style={{ fontSize: '1em' }}>
                Discharged from NPSC, Jacksonville, Florida.
                </p>
            </Grid.Column>
        </Grid.Row>

        <Grid.Row textAlign='center'>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '1em' }}>
            <img src={bars} class="articlePhoto"  alt="Clyde Richardson's Military Ribbons" />
                <p class="sidePadding" style={{ fontSize: '1em' }}>
                1. American Campaign Ribbon: For service during World War II within the American Theater of Operations. <br/> <br/>
                2. Asiatic–Pacific Campaign Ribbon: Awarded to any member of the United States Armed Forces who served in the Asiatic-Pacific Theater from 1941 to 1945. (1 star for participation in each war patrol) <br/> <br/>
                3. European–African–Middle Eastern Campaign Ribbon: Awarded to recognize those military service members who had performed military duty in the European Theater (to include North Africa and the Middle East) during the years of the Second World War.  <br/> <br/>
                4. Philippine Liberation Ribbon: Awarded to recognize military service during the last eleven months of World War II when the military of Japan was driven from the Philippines before their surrender in September 1945. <br/>
                5. Legion of Merit Ribbon: Awarded for exceptionally meritorious conduct in the performance of outstanding services and achievements. <br/> <br/>
                6. World War II Victory Ribbon: Awarded for service between 7 December 1941 and 31 December 1946. <br/>

                </p>
                
            </Grid.Column>
        </Grid.Row>
        </Grid>
        </SRLWrapper>
</SimpleReactLightbox>
    </Segment>
</React.Fragment>

)

export default ClydeWW2