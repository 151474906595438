import React from 'react'
import { Button, Divider, Segment, Header, Grid } from 'semantic-ui-react'

import SimpleReactLightbox from "simple-react-lightbox";
import { SRLWrapper } from "simple-react-lightbox";

import carol80 from '../images/articlePictures/carol80.png'
import ceremony81 from '../images/articlePictures/ceremony81.png'
import carolparentswedding81 from '../images/articlePictures/carolparentswedding81.png'
import wedding81 from '../images/articlePictures/wedding81.png'
import weddingparty81 from '../images/articlePictures/weddingparty81.png'
import scott84 from '../images/articlePictures/scott84.png'
import scott85 from '../images/articlePictures/scott85.png'
import kevin88 from '../images/articlePictures/kevin88.png'
import kevin89 from '../images/articlePictures/kevin89.png'

import scottkevin88 from '../images/articlePictures/scottkevin88.png'
import familyPortrait91 from '../images/articlePictures/familyPortrait91.png'
import scottkimcarol93 from '../images/articlePictures/scottkimcarol93.png'
import columbia95 from '../images/articlePictures/columbia95.png'
import disney96 from '../images/articlePictures/disney96.png'
import scottkevin97 from '../images/articlePictures/scottkevin97.png'
import kimkevinlondo03 from '../images/articlePictures/kimkevinlondo03.png'
import cruise06 from '../images/articlePictures/cruise06.png'
import kimandboys88 from '../images/articlePictures/kimandboys88.png'
import kimkevin19 from '../images/articlePictures/kimkevin19.png'

const KimCarolMatch = () => (

<React.Fragment>
<Segment  style={{ padding: '0em 0em' }} vertical>
    <Grid celled='internally' columns='equal' stackable>
        <Grid.Row textAlign='center'>
            <Grid.Column  style={{ paddingBottom: '0em', paddingTop: '0em' }}>
                <br/>
                <Header as='h3' style={{ fontSize: '2em' }}>Kim &amp; Carol <br/> A Perfect Match </Header>
                <br/>

            </Grid.Column>
        </Grid.Row>

        <Grid.Row textAlign='left'>
            <Grid.Column  style={{ paddingBottom: '1em', paddingTop: '.5em' }} >
                <p class="sidePadding" style={{ fontSize: '1.33em' }}>
                <br/>
                On Wednesday, June 5, 1980, Kim and Carol were introduced to each other at a Tee-Ball game by Cindy Roberts.  Cindy worked with Kim and had a son in Carol’s First Grade class.  At the game, a first date was set up for three days later, to go to an Astros Game.
                 <br/>
                 <br/>
                 <SimpleReactLightbox>
                    <SRLWrapper>
                        <img src={carol80} class="articlePhoto" alt="1980 - Carol" />
                <br/>
                <br/>
                For the next 401 days, they saw each other 228 of those days.  They went to the Windmill Dinner Theater three times, Astros games, Rocket games, Ferrante and Teicher, movies, and more.  On November 26, 1980, they drove to Gainesville, Florida to meet Kim’s family and to watch the Gators play Miami and also went to Disney World.  On May 21, 1981, they drove to St. Louis to visit Carol’s sister, Kathy and her then husband Steve.   Also during this time Carol taught her first graders, and Kim worked at Central Freight lines during the day and Sears at night.  One weekend a month and two weeks during the year he served with the Naval Reserve.
                <br/>
                <br/>
                They got married on July 11, 1981 and spent a short honeymoon in South Florida.
                <br/>
                <br/>
                <div class="ui four column grid">
                <div class="column">
                    <img src={ceremony81} class="articlePhotoRow" alt="July 11, 1981 - The Ceremony" />
                </div>
                <div class="column">
                    <img src={carolparentswedding81} class="articlePhotoRow" alt="Carol and her parents, Gloria &amp; Joe" />
                </div>
                <div class="column">
                    <img src={wedding81} class="articlePhotoRow" alt="July 11, 1981 - Carol &amp; Kim" />
                </div>
                <div class="column">
                <img src={weddingparty81} class="articlePhotoRow" alt="The Richardsons. From right to left: Gail, Karen, Jay, Carol, Kim, Edna, &amp; Clyde" />
                </div>
                </div>
                The reception was held at Steak &amp; Ale.  Everyone seemed to have a good time.  Steve made the toast.  Jay, Carol’s Mom, Cindy and Sharon handled cutting the cake and serving.
                <br/>
                <br/>
                Kim &amp; Carol were blessed with a son, Scott Frank was born on April 13, 1984.
                <br/>
                <br/>
                <div class="ui two column grid">
                <div class="column">
                    <img src={scott84} class="articlePhoto" alt="1984 - Scott" />
                </div>
                <div class="column">
                    <img src={scott85} class="articlePhoto" alt="1985 - Scott" />
                </div>
                </div>
                <br/>
                <br/>
                On March 1, 1988, Kevin was introduced to the family.
                <br/>
                <br/>
                <div class="ui two column grid">
                <div class="column">
                    <img src={kevin88} class="articlePhoto" alt="1988 - Kevin" />
                </div>
                <div class="column">
                    <img src={kevin89} class="articlePhoto" alt="1989 - Kevin" />
                </div>
                </div>
                Over the years, our family had many adventures and made lots of memories. Below are some photos of a few of them.
                <br/>
                <br/>
                <div class="ui five column grid">
                <div class="column">
                    <img src={scottkevin88} class="articlePhotoRow" alt="1988 - Scott &amp; Kevin" />
                </div>
                <div class="column">
                <img src={kimandboys88} class="articlePhotoRow" alt="1988 - Kim and his sons" />
                </div>
                <div class="column">
                    <img src={familyPortrait91} class="articlePhotoRow" alt="1991 - The Richardsons" />
                </div>
                <div class="column">
                    <img src={scottkimcarol93} class="articlePhotoRow" alt="1993 - Scott, Kim, &amp; Carol at the Grand Canyon" />
                </div>
                <div class="column">
                <img src={columbia95} class="articlePhotoRow" alt="April 1995 - Scott &amp; Kevin pose in front of the Space Shuttle Columbia" />
                </div>
                </div>
                <br/>
                <br/>
                <div class="ui five column grid">
                <div class="column">
                <img src={disney96} class="articlePhotoRow" alt="1996 - Carol, Kim, Kevin, &amp; Scott at Disneyworld in front of 'Ariels Grotto', the former site of the infamous 20,000 Leagues Under the Sea: Submarine Voyage ride." />
                </div>
                <div class="column">
                    <img src={scottkevin97} class="articlePhotoRow" alt="1997 - Scott &amp; Kevin" />
                </div>
                <div class="column">
                    <img src={kimkevinlondo03} class="articlePhotoRow" alt="2003 - Kim &amp; Kevin on the London Eye" />
                </div>
                <div class="column">
                    <img src={cruise06} class="articlePhotoRow" alt="2006 - Carol, Kevin, Kim, &amp; Scott during a cruise" />
                </div>
                <div class="column">
                <img src={kimkevin19} class="articlePhotoRow" alt="2019 - Kim &amp; Kevin" />
                </div>
                </div>
                </SRLWrapper>
                </SimpleReactLightbox>
                </p>
            </Grid.Column>
        </Grid.Row>
        </Grid>
    </Segment>
</React.Fragment>
)

export default KimCarolMatch